.grid-custom{
    padding:0;
    margin-right: 10px;
}
.grid-custom2{
    padding:0;
    margin-right: -10px;
}
.banner-search-checkbox{
    font-weight: bold;
    color:white;
    font-family: auto;
    margin-left:10px
}
.bolde{
    font-size:18px;
    font-weight:bolder;
}